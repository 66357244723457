import React from "react"
import { Link } from "gatsby"

export default (props) => {
return (		
		<div className="container">
			<div className="row topnav">							
				<div className="col d-flex justify-content-center">
					<div className="container topnav-inner" style={{width: "720px"}}>
						<div classname="row">
							<div className="col">
								<Link to="/"><span>h</span>ome</Link>		
								{props.olderPath && <Link to={props.olderPath}><span>o</span>lder</Link>}
								{props.newerPath && <Link to={props.newerPath}><span>n</span>ewer</Link>}				
								{/*<Link to="/#tags"><span>t</span>ags</Link>*/}
								{/*<Link to="#search"><span>s</span>earch</Link>*/}
								<Link to="/about">about</Link>
							</div>
						</div>
					</div>
				</div>									
			</div>
		</div>		
	)
}
