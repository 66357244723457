import React from "react"
import Header from "../components/header"

export default (thing) => {
  return(	  
  	<div>
	    <Header
	    	newerPath={thing.newerPath}
	    	olderPath={thing.olderPath}
	    	/>
		{thing.children}  
	</div>
	)
}
